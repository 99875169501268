import React, { ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
// import { MoneyCollectOutlined } from '@ant-design/icons';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Snackbar,
  StyleRules,
  Tab,
  Tabs,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CheckIcon from '@material-ui/icons/Check';
import FilterListIcon from '@material-ui/icons/FilterList';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import PeopleIcon from '@material-ui/icons/People';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SendIcon from '@material-ui/icons/Send';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Alert from '@material-ui/lab/Alert';

import {
  companyGetAllProjectBid,
  companyGetAllProjectBidNotAvatar,
  companyUpdateProjectBidStatus,
} from '../../actions/projectBidAction';
import {
  PassCompanyBiddingProjectDialog,
  RefuseCompanyBiddingProjectDialog,
} from '../../components/Dialog';
import DialogTitles from '../../components/DialogTitle';
import ProjectPreviewView from '../../components/ProjectPreviewView';
import { AppState } from '../../reducers';
import { companyMenuAction } from '../../reducers/companyMenuReducer';
import { calculateAge } from '../../utils/calculateAge';
import city from '../../utils/city';
import cityChild from '../../utils/cityChild';
import { formatDate } from '../../utils/dateFormat';
import CompetitiveBiddingDetailsView from './CompetitiveBiddingDetailsView';
import ConsultantInfoDetailsView from './ConsultantInfoDetailsView';
const styles = (): StyleRules =>
  createStyles({
    contentPadding: {
      minHeight: '80vh',
      padding: '20px',
    },
    childrenItem: {
      width: '100%',
      borderRadius: '3px',
      height: '625px',
    },
    buttonMargin: {
      margin: '0 0.25rem',
    },
    avatar: {
      borderRadius: '50%',
      width: '3rem',
      height: '3rem',
      margin: '0',
      '&:hover': {
        boxShadow: '0px 4px 16px 3px rgba(0,0,0,0.2)',
      },
    },
    listBox: {
      padding: '1rem',
      border: '1px solid #ccc',
    },
    verticalLine: {
      margin: '0 0.5rem',
      color: '#ccc',
    },
    h3: {
      margin: '1rem 0',
      textAlign: 'center',
    },
    list: {
      background: '#eee',
    },
    listContainer: {
      padding: '1.25rem',
      height: 'calc(625px - 72px)',
      overflowY: 'scroll',
    },
    listItem: {
      padding: '1.25rem',
      borderRadius: '1rem',
      background: '#fff',
      marginBottom: '1.25rem',
      position: 'relative',
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        boxShadow: '0px 4px 16px 0px rgba(0,0,0,0.2)',
      },
    },
    itemTop: {
      display: 'flex',
      alignItems: 'center',
    },
    projectName: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: '#333',
      marginRight: '1rem',
      display: 'flex',
      alignItems: 'center',
    },
    statusTag: {
      width: '3.5rem',
      height: '1.25rem',
      textAlign: 'center',
      lineHeight: '1.25rem',
      fontSize: '0.75rem',
      color: '#ff4d4f',
      border: '1px solid #ff4d4f',
      marginRight: '2rem',
      borderRadius: '0.6rem',
    },
    salary: {
      color: '#242f65',
      fontSize: '1rem',
      display: 'flex',
      alignItems: 'center',
    },
    spread: {
      background: '#f4f4f5',
      borderBottomLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      height: '1.5rem',
      textAlign: 'center',
      lineHeight: '1.5rem',
      color: '#4c4f54',
      fontSize: '0.75rem',
      position: 'absolute',
      padding: '0.35rem',
      top: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
    },

    itemMiddle: {
      margin: '1.25rem 0',
      display: 'flex',
      alignItems: 'center',
    },
    middleDescTag: {
      background: '#f4f4f5',
      textAlign: 'center',
      lineHeight: '1.5rem',
      color: '#4c4f54',
      fontSize: '0.75rem',
      padding: '0.2rem 0.5rem',
      marginRight: '0.5rem',
    },
    itemBottom: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    bottomLeft: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      width: '2rem',
      height: '2rem',
      marginBottom: 0,
    },
    companyName: {
      color: '#333',
      fontWeight: 'bold',
      margin: '0 0.75rem 0 0.5rem',
      // fontSize: '1.5rem',
    },
    text: {
      fontSize: '0.75rem',
      color: '#7c8087',
      marginLeft: '0.5rem',
    },
    delivery: {
      fontSize: '1rem',
      color: '#242f65',
      fontWeight: 'bold',
      '&:hover': {
        textShadow: '0px 4px 16px 0px rgba(0,0,0,0.2)',
      },
    },
  });

export enum RecruitmentStatus {
  InitialScreening,
  Interview,
  NotSuitable,
}
const competitiveBiddingListView = ({ classes }: Props): ReactElement => {
  const companyAllProjectBidReducer = useSelector(
    (appState: AppState) => appState.companyAllProjectBid
  );
  const [value, setValue] = useState(0);
  const [isPassBiddingOpen, setIsPassBiddingOpen] = useState(false);
  const [isRefuseBiddingOpen, setIsRefuseBiddingOpen] = useState(false);
  const [bid, setBid] = useState<number>();
  const [bidItem, setBidItem] = useState<Bidding_info>();
  const [companyProjectBidData, setCompanyProjectBidData] = useState<Bidding_info[]>([]);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const [showOpen, setShowOpen] = React.useState(false);
  const [showConsultantOpen, setShowConsultantOpen] = React.useState(false);
  const [showProject, setShowProject] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const handleMessageClose = () => {
    setMessageOpen(false);
  };
  const handleBiddingClose = () => {
    setShowOpen(false);
  };

  const handleConsultantClose = () => {
    setShowConsultantOpen(false);
  };

  const handleProjectClose = () => {
    setShowProject(false);
  };

  const dispatch = useDispatch();

  const [showEnterOpen, setShowEnterOpen] = React.useState(false);
  const [backFirstRound, setBackFirstRound] = React.useState(false);

  const handleBackFirstRoundClose = () => {
    setBackFirstRound(false);
  };

  const fetchBidData = async () => {
    await dispatch(companyGetAllProjectBid());
  };

  const fetchNoAvatarBidData = async () => {
    await dispatch(companyGetAllProjectBidNotAvatar());
    setTimeout(() => {
      fetchBidData();
    }, 1500);
  };

  const filterData = (): void => {
    if (
      companyAllProjectBidReducer &&
      companyAllProjectBidReducer.companyProjectBidList?.length > 0
    ) {
      switch (value) {
        case 0: {
          const newPublishedList = companyAllProjectBidReducer.companyProjectBidList.filter(
            item =>
              item.companyBidStatus == 'PASSED_FIRST_ROUND' ||
              item.companyBidStatus == 'NONE' ||
              !item.companyBidStatus
          );
          setCompanyProjectBidData([...newPublishedList] as []);
          break;
        }
        case 1: {
          const newPublishedList = companyAllProjectBidReducer.companyProjectBidList.filter(
            item =>
              item.companyBidStatus == 'IN_NEGOTIATION' &&
              item.consultantBidStatus !== 'CONFIRMED_BID'
          );
          setCompanyProjectBidData([...newPublishedList] as []);
          break;
        }
        case 2: {
          const newPublishedList = companyAllProjectBidReducer.companyProjectBidList.filter(
            item => item.consultantBidStatus === 'CONFIRMED_BID'
          );
          setCompanyProjectBidData([...newPublishedList] as []);
          break;
        }
        case 3: {
          const newPublishedList = companyAllProjectBidReducer.companyProjectBidList.filter(
            item => item.companyBidStatus === 'NOT_SUITABLE'
          );
          setCompanyProjectBidData([...newPublishedList] as []);
          break;
        }
      }
    }
  };

  const firstPass = async () => {
    const data = await dispatch(companyUpdateProjectBidStatus(bid, 'IN_NEGOTIATION'));
    if (data.companyBidStatus) {
      setStatusMessage('操作成功');
      setMessageOpen(true);
    }
    setIsPassBiddingOpen(false);
    fetchNoAvatarBidData();
  };

  const refusePass = async () => {
    const data = await dispatch(companyUpdateProjectBidStatus(bid, 'NOT_SUITABLE'));
    if (data.companyBidStatus) {
      setStatusMessage('操作成功');
      setMessageOpen(true);
    }
    setIsRefuseBiddingOpen(false);
    fetchNoAvatarBidData();
  };

  const backPassedFirstRound = async () => {
    const data = await dispatch(companyUpdateProjectBidStatus(bid, 'PASSED_FIRST_ROUND'));
    if (data.companyBidStatus) {
      setStatusMessage('操作成功');
      setMessageOpen(true);
    }
    setBackFirstRound(false);
    fetchNoAvatarBidData();
  };

  useEffect(() => {
    fetchNoAvatarBidData();
    dispatch(
      companyMenuAction({
        menuStatus: {
          home: false,
          publishProject: false,
          projectList: false,
          biddingList: true,
          pendingProject: false,
        },
      })
    );
  }, []);

  useEffect(() => {
    filterData();
  }, [companyAllProjectBidReducer]);

  useEffect(() => {
    filterData();
  }, [value]);

  return (
    <>
      <Helmet title="企业申请管理"></Helmet>
      {/* <h3 className={classes.h3}>申请浏览</h3> */}
      <Grid container className={classes.contentPadding} justifyContent="space-between">
        <Paper square className={classes.childrenItem}>
          <Tabs
            variant="fullWidth"
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="初筛" icon={<FilterListIcon />} />
            <Tab label="洽谈中" icon={<PeopleIcon />} />
            <Tab label="已发送offer" icon={<SendIcon />} />
            <Tab label="不合适" icon={<NotInterestedIcon />} />
          </Tabs>
          <div className={classes.list}>
            <div className={classes.listContainer}>
              {companyProjectBidData.map(item => {
                return (
                  <div className={classes.listItem} key={item.id}>
                    <div className={classes.itemTop}>
                      <div
                        className={classes.projectName}
                        onClick={() => {
                          setBid(item.request?.id);
                          setShowProject(true);
                        }}
                      >
                        <AssignmentIcon /> {item.request?.projectName}
                      </div>
                      {/* {item.projectStatus === 2 ? (
                        <div className={classes.statusTag}>急聘</div>
                      ) : null} */}
                      <span className={classes.verticalLine}></span>
                      <div className={classes.salary}>
                        {/* <AttachMoneyIcon /> */}
                        申请价：
                        {item.bidSalary}
                      </div>
                      <div className={classes.spread}>
                        <ScheduleIcon fontSize="small" />
                        {item.request?.projectStartAsap
                          ? item.request.projectStartAsap
                          : formatDate(new Date(`${item.request?.projectStartTime}`), 'yyyy-MM-dd')}
                        {item.request?.projectEndTime ? (
                          <span className={classes.verticalLine}>-</span>
                        ) : null}
                        {formatDate(new Date(`${item.request?.projectEndTime}`), 'yyyy-MM-dd')}
                      </div>
                    </div>
                    {/* <div className={classes.itemMiddle}>
                      {item.projectDescriptions.map(descItem => {
                        return (
                          <div className={classes.middleDescTag} key={descItem}>
                            {descItem}
                          </div>
                        );
                      })}
                      值
                    </div> */}
                    <p></p>
                    <div className={classes.itemBottom}>
                      <div
                        className={classes.bottomLeft}
                        onClick={() => {
                          setBid(item.id);
                          setShowConsultantOpen(true);
                        }}
                      >
                        <img
                          src={item.consultant?.verification?.basicInfo?.avatar}
                          alt="User Avatar"
                          className={classes.avatar}
                        ></img>
                        <span className={classes.companyName}>
                          {`${item.consultant?.verification?.basicInfo?.firstName}${item.consultant?.verification?.basicInfo?.lastName}`}
                        </span>
                        <span className={classes.text}>
                          {item.consultant?.verification?.basicInfo?.birthday
                            ? calculateAge(item.consultant?.verification?.basicInfo?.birthday)
                            : ''}
                          岁
                        </span>
                        <span className={classes.text}>
                          {
                            city.find(
                              c =>
                                c.code === item.consultant?.verification?.basicInfo?.nowAddressCity
                            )?.name
                          }
                          {item.consultant?.verification?.basicInfo?.nowAddressDistrict ? (
                            <span className={classes.verticalLine}>-</span>
                          ) : null}
                          {
                            cityChild.find(
                              c =>
                                c.code ===
                                item.consultant?.verification?.basicInfo?.nowAddressDistrict
                            )?.name
                          }
                        </span>
                      </div>
                      <div className={classes.bottomRight}>
                        {/* <span className={classes.text}>地址</span> */}
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.buttonMargin}
                          onClick={() => {
                            setBid(item.id);
                            value == 1 ? setBidItem(item) : null;

                            value == 1 ? setShowEnterOpen(true) : setShowEnterOpen(false);
                            setShowOpen(true);
                          }}
                        >
                          <VisibilityIcon fontSize="small" /> 查看
                        </Button>
                        {value == 0 && (
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.buttonMargin}
                            onClick={() => {
                              setBid(item.id);
                              setIsPassBiddingOpen(true);
                            }}
                          >
                            <CheckIcon fontSize="small" /> 通过初筛
                          </Button>
                        )}
                        {value == 3 && (
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.buttonMargin}
                            onClick={() => {
                              setBid(item.id);
                              setBackFirstRound(true);
                            }}
                          >
                            <SettingsBackupRestoreIcon fontSize="small" /> 回到初筛
                          </Button>
                        )}
                        {value == 1 && (
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.buttonMargin}
                            onClick={() => {
                              setBid(item.id);
                              setBidItem(item);
                              setShowEnterOpen(true);
                              setShowOpen(true);
                            }}
                          >
                            <AssignmentTurnedInIcon fontSize="small" /> offer发送
                          </Button>
                        )}

                        {value != 2 && (
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.buttonMargin}
                            onClick={() => {
                              setBid(item.id);
                              setIsRefuseBiddingOpen(true);
                            }}
                          >
                            <NotInterestedIcon fontSize="small" /> 不合适
                          </Button>
                        )}
                        {/* <div className={classes.delivery}>立即投递</div> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Paper>

        <Dialog onClose={handleConsultantClose} open={showConsultantOpen} maxWidth="lg">
          <DialogTitles id="customized-dialog-title" onClose={handleConsultantClose}>
            顾问信息
          </DialogTitles>
          <DialogContent dividers>
            <ConsultantInfoDetailsView
              onClose={handleConsultantClose}
              bid={bid}
            ></ConsultantInfoDetailsView>
          </DialogContent>
        </Dialog>

        <Dialog onClose={handleProjectClose} open={showProject} maxWidth="lg">
          <DialogTitles id="customized-dialog-title" onClose={handleProjectClose}>
            项目详情
          </DialogTitles>
          <DialogContent dividers>
            <ProjectPreviewView requestId={bid} overHideShare={true}></ProjectPreviewView>
          </DialogContent>
        </Dialog>

        <Dialog onClose={handleBiddingClose} open={showOpen} maxWidth="lg">
          <DialogTitles id="customized-dialog-title" onClose={handleBiddingClose}>
            申请信息
          </DialogTitles>
          <DialogContent dividers>
            <CompetitiveBiddingDetailsView
              onClose={handleBiddingClose}
              statusValue={value}
              subOffer={showEnterOpen}
              bid={bid}
              bidItem={bidItem}
              fetchBidData={fetchNoAvatarBidData}
            ></CompetitiveBiddingDetailsView>
          </DialogContent>
        </Dialog>

        <Dialog open={backFirstRound} scroll="paper" onClose={handleBackFirstRoundClose}>
          <DialogTitle>回到初筛</DialogTitle>
          <DialogContent>确认申请信息回到初筛中么？</DialogContent>
          <DialogActions style={{ textAlign: 'center' }}>
            <Button onClick={backPassedFirstRound} variant="contained" color="primary">
              是
            </Button>
            <Button onClick={handleBackFirstRoundClose} variant="contained">
              否
            </Button>
          </DialogActions>
        </Dialog>
        {/* 初筛 */}
        <PassCompanyBiddingProjectDialog
          isOpen={isPassBiddingOpen}
          handleDialogClose={() => {
            setIsPassBiddingOpen(false);
          }}
          handleEdit={firstPass}
        ></PassCompanyBiddingProjectDialog>
        {/* 不合适 */}
        <RefuseCompanyBiddingProjectDialog
          isOpen={isRefuseBiddingOpen}
          handleDialogClose={() => {
            setIsRefuseBiddingOpen(false);
          }}
          handleEdit={refusePass}
        ></RefuseCompanyBiddingProjectDialog>
      </Grid>
      <Snackbar
        open={messageOpen}
        autoHideDuration={5000}
        onClose={handleMessageClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={handleMessageClose} severity="success">
          {statusMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(competitiveBiddingListView);
