import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  StyleRules,
  TextField,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { companySendProjectOffer } from '../../actions/companyOfferAction';
import {
  PassCompanyBiddingProjectDialog,
  RefuseCompanyBiddingProjectDialog,
} from '../../components/Dialog';
import { showNotificationAction } from '../../reducers/notificationReducer';
import { createCompanyRequestOfferInfo } from './companyOffer.model';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    content: {
      border: '5px solid rgba(0, 0, 0, 0.1)',
      margin: '1rem 0',
      [theme.breakpoints.up('md')]: {
        // width: '50rem',
        padding: '1rem',
      },
      [theme.breakpoints.up('sm')]: {
        padding: '1rem',
      },
    },
    buttonMargin: {
      margin: '1rem',
      display: 'line-block',
    },

    littleTittle: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
    },

    iconLineHeight: {
      marginLeft: '1rem',
    },

    textMargin: {
      margin: '0',
    },
    borderMargin: {
      marginTop: '1.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    itemML: {
      width: '6rem',
    },
  });

const sendOfferView = ({ classes, onClose, bid, request }: Props): ReactElement => {
  const dispatch = useDispatch();
  const [isPassBiddingOpen, setIsPassBiddingOpen] = useState(false);
  const [isRefuseBiddingOpen, setIsRefuseBiddingOpen] = useState(false);
  const [offerDialogOpen, setOfferDialogOpen] = useState(false);
  const { setValue, getValues, trigger, control } = useForm({
    defaultValues: {
      offer: createCompanyRequestOfferInfo(),
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const handleOfferDialogClose = () => {
    setOfferDialogOpen(false);
  };
  const { isDirty, errors, isValid } = useFormState({ control });

  const handleSendOffer = async () => {
    if (isDirty) {
      trigger('offer');
      if (isValid) {
        if (!bid) {
          dispatch(
            showNotificationAction({
              severity: 'error',
              message: '缺少项目信息请检查',
            })
          );
          return;
        }

        const offer = {
          ...getValues('offer'),
          workScenario: JSON.stringify(getValues('offer.workScenario')),
          status: 'COMPANY_SENT',
        };

        if (bid) {
          offer.bid = { id: bid };
        }
        await dispatch(companySendProjectOffer(offer));

        onClose ? onClose() : '';
        setOfferDialogOpen(false);
      } else {
        dispatch(
          showNotificationAction({
            severity: 'error',
            message: '输入信息格式不正确请检查',
          })
        );
      }
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: '请填入信息',
        })
      );
    }
  };

  useEffect(() => {
    if (request) {
      setValue('offer.offerName', request.projectName);
      setValue('offer.offerContent', request.projectContent);
      if (typeof request.workScenario === 'string') {
        setValue('offer.workScenario', JSON.parse(request.workScenario));
      }
      setValue('offer.workload', request.workload);
      setValue('offer.workLocationAddress', request.workLocationAddress);
      setValue('offer.projectEndTime', new Date(String(request.projectEndTime)));
      request.projectStartTime
        ? setValue('offer.projectStartTime', request.projectStartTime)
        : setValue('offer.projectStartTime', new Date());
    }
  }, [request]);

  return (
    <>
      <Grid xs={12} className={classes.content} container>
        <div className={classes.littleTittle}>
          <LocalOfferIcon></LocalOfferIcon> offer
        </div>
        <Grid item xs={12} className={classes.borderMargin} container>
          <Grid item className={classes.itemML}>
            *项目名称：
          </Grid>
          <Grid xs={8}>
            <Controller
              render={({ field }) => (
                <TextField
                  className={classes.textMargin}
                  variant="outlined"
                  required
                  fullWidth
                  id="offerName"
                  label="项目名称"
                  size="small"
                  error={!!errors?.offer?.offerName}
                  helperText={!!errors?.offer?.offerName && '请填写正确的输入值'}
                  {...field}
                />
              )}
              name="offer.offerName"
              control={control}
              rules={{ required: true }}
            ></Controller>
          </Grid>
          <Grid xs={1}>
            <ErrorOutlineIcon className={classes.iconLineHeight} />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.borderMargin} container>
          <Grid item className={classes.itemML}>
            *项目内容：
          </Grid>
          <Grid xs={8}>
            <Controller
              render={({ field }) => (
                <TextField
                  size="small"
                  variant="outlined"
                  className={classes.textMargin}
                  required
                  fullWidth
                  placeholder="请输入项目内容"
                  autoFocus={false}
                  multiline
                  minRows={4}
                  id="offerContent"
                  label="项目内容"
                  error={!!errors?.offer?.offerContent}
                  helperText={!!errors?.offer?.offerContent && '请输入项目内容'}
                  {...field}
                />
              )}
              name="offer.offerContent"
              control={control}
              rules={{ required: true }}
            ></Controller>
          </Grid>

          <Grid xs={1}>
            <ErrorOutlineIcon className={classes.iconLineHeight} />
          </Grid>
        </Grid>
        <Grid xs={12} className={classes.borderMargin} container>
          <Grid item className={classes.itemML}>
            *工作方式：
          </Grid>
          <Grid xs={8} container>
            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" {...field} checked={field.value} name="onsite" />
                    }
                    label="现场"
                  />
                )}
                name={'offer.workScenario.onsite'}
                control={control}
              ></Controller>
            </Grid>
            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" {...field} checked={field.value} name="remote" />
                    }
                    label="远程"
                  />
                )}
                name={'offer.workScenario.remote'}
                control={control}
              ></Controller>
            </Grid>
          </Grid>

          <Grid xs={1}>
            <ErrorOutlineIcon className={classes.iconLineHeight} />
          </Grid>
        </Grid>
        <Grid item xs={12} container className={classes.borderMargin}>
          <Grid item className={classes.itemML}>
            *工作量：
          </Grid>
          <Grid xs={8}>
            <Controller
              render={({ field }) => (
                <TextField
                  size="small"
                  variant="outlined"
                  required
                  fullWidth
                  id="workload"
                  label="工作量"
                  error={!!errors.offer?.workload}
                  helperText={!!errors.offer?.workload && '工作量不能为空'}
                  {...field}
                />
              )}
              name="offer.workload"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
          </Grid>
          <Grid xs={1}>
            <ErrorOutlineIcon className={classes.iconLineHeight} />
          </Grid>
        </Grid>

        <Grid item xs={12} container className={classes.borderMargin}>
          <Grid item className={classes.itemML}>
            *详细地址：
          </Grid>
          <Grid item xs={8}>
            <Controller
              render={({ field }) => (
                <TextField
                  size="small"
                  variant="outlined"
                  required
                  fullWidth
                  id="workLocationAddress"
                  label="详细地址"
                  error={!!errors.offer?.workLocationAddress}
                  helperText={!!errors.offer?.workLocationAddress && '请填写详细地址'}
                  {...field}
                />
              )}
              name="offer.workLocationAddress"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
          </Grid>

          <Grid xs={1}></Grid>
        </Grid>
        <Grid container xs={12} className={classes.borderMargin}>
          <Grid item className={classes.itemML}>
            *项目时间：
          </Grid>
          <Grid container xs={8} justifyContent="space-between">
            <Grid xs={5}>
              <Controller
                render={({ field }) => (
                  <>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        variant="inline"
                        format="yyyy-MM-dd"
                        views={['date']}
                        size="small"
                        // views={['year', 'month']}
                        id="date-picker-start"
                        label="开始时间"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        {...field}
                        error={!!errors.offer?.projectStartTime}
                        helperText={!!errors.offer?.projectStartTime && '开始时间不能为空'}
                        value={field.value ?? null}
                      />
                    </MuiPickersUtilsProvider>
                  </>
                )}
                name={`offer.projectStartTime`}
                rules={{ required: true }}
                control={control}
              ></Controller>
            </Grid>
            <Grid xs={5}>
              <Controller
                render={({ field }) => (
                  <>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        variant="inline"
                        format="yyyy-MM-dd"
                        id="date-picker-end"
                        label="结束时间"
                        size="small"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        {...field}
                        error={!!errors.offer?.projectEndTime}
                        helperText={!!errors.offer?.projectEndTime && '结束时间不能为空'}
                        value={field.value ?? null}
                      />
                    </MuiPickersUtilsProvider>
                  </>
                )}
                name={`offer.projectEndTime`}
                rules={{ required: true }}
                control={control}
              ></Controller>
            </Grid>
          </Grid>

          <Grid xs={1}>
            <ErrorOutlineIcon className={classes.iconLineHeight} />
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.borderMargin} container>
          <Grid item className={classes.itemML}>
            *项目酬劳：
          </Grid>
          <Grid xs={8}>
            <Controller
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  id="projectRemuneration"
                  label="项目酬劳"
                  {...field}
                  error={!!errors.offer?.projectRemuneration}
                  helperText={!!errors.offer?.projectRemuneration && '项目酬劳不能为空'}
                />
              )}
              name="offer.projectRemuneration"
              control={control}
              rules={{ required: true }}
            ></Controller>
          </Grid>

          <Grid xs={1}>
            <ErrorOutlineIcon className={classes.iconLineHeight} />
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.borderMargin} container>
          <Grid item className={classes.itemML}>
            *联系方式：
          </Grid>
          <Grid xs={8}>
            <Controller
              render={({ field }) => (
                <TextField
                  size="small"
                  variant="outlined"
                  required
                  fullWidth
                  minRows={2}
                  multiline
                  {...field}
                  id="contactInfo"
                  label="项目联系方式"
                  error={!!errors.offer?.contactInfo}
                  helperText={!!errors.offer?.contactInfo && '项目联系方式不能为空'}
                />
              )}
              name="offer.contactInfo"
              control={control}
              rules={{ required: true }}
            ></Controller>
          </Grid>

          <Grid xs={1}>
            <ErrorOutlineIcon className={classes.iconLineHeight} />
          </Grid>
        </Grid>

        <Grid item xs={12} container justifyContent="center">
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.buttonMargin}
            onClick={() => {
              setOfferDialogOpen(true);
            }}
          >
            发送offer
          </Button>
        </Grid>
      </Grid>

      <Dialog open={offerDialogOpen} scroll="paper" onClose={handleOfferDialogClose}>
        <DialogTitle>发送offer</DialogTitle>
        <DialogContent>确认给顾问发送offer么？</DialogContent>
        <DialogActions style={{ textAlign: 'center' }}>
          <Button onClick={handleSendOffer} variant="contained" color="primary">
            是
          </Button>
          <Button onClick={handleOfferDialogClose} variant="contained">
            否
          </Button>
        </DialogActions>
      </Dialog>
      <PassCompanyBiddingProjectDialog
        isOpen={isPassBiddingOpen}
        handleDialogClose={() => {
          setIsPassBiddingOpen(false);
          onClose ? onClose() : '';
        }}
        handleEdit={() => {
          setIsPassBiddingOpen(false);
          onClose ? onClose() : '';
        }}
      ></PassCompanyBiddingProjectDialog>

      <RefuseCompanyBiddingProjectDialog
        isOpen={isRefuseBiddingOpen}
        handleDialogClose={() => {
          setIsRefuseBiddingOpen(false);
          onClose ? onClose() : '';
        }}
        handleEdit={() => {
          setIsRefuseBiddingOpen(false);
          onClose ? onClose() : '';
        }}
      ></RefuseCompanyBiddingProjectDialog>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onClose?: Function;
  bid?: number;
  request?: ProjectRequestInfo | undefined;
}

export default withStyles(styles)(sendOfferView);
